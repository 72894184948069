import { useTheme } from '@emotion/react'
import {
  ActionButton,
  IDropdownOption,
  ProgressIndicator,
  TooltipHost
} from '@fluentui/react'
import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import {
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  getFilteredRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  GroupingState
} from '@tanstack/react-table'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { fromPairs, sum } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useStore } from 'react-redux'
import { HorizontalScrollContainer } from 'shared/components/HorizontalScrollContainer'
import { downloadUrlAsFile } from 'shared/downloads'
import { useDebounce } from 'shared/hooks/useDebounce'
import { Dropdown } from '../../components/shared'
import DetailsNavigator from '../../components/shared/DetailsNavigator/DetailsNavigator'
import { Searchbox } from '../../components/shared/DetailTables/Searchbox'
import Toggle from '../../components/shared/Toggle'
import { useGainLossesStore } from '../../features/GainLosses/gainLossesStore'
import { useYearRangeOptions } from '../../features/GainLosses/YearRangeOptionsHook'
import { Icon } from '../../features/Icons/Icon'
import { PrintDropdownMenu } from '../../shared/PrintDropdownMenu'
import {
  useFinancialsApiUtil,
  useGetRealizedGainLossSecurityQuery
} from '../../store/rdot360AnalyticsApi'
import { useRdot360SelectedAccountsApiContext } from '../../store/rdot360Context'
import {
  IClosedLotDetailSummary,
  IClosedLotSummaryItem
} from '../../store/types'
import { disclaimer } from './disclaimer'
import { RealizedGainAndLossExportWorker } from './export'
import {
  GainLossHeading,
  GainLossBody,
  GainLossFooter
} from './feature/GainLossTable'
import { getColumnDefinition } from './feature/GainLossTable/getColumnDefinition'
import { globalFilterFn } from './feature/GainLossTable/globalFilterFn'
import { openGainLossPrintTab } from './feature/PrintView/PrintView'
import { GainLossDetailColumnNames } from './shared'

type ViewBy = 'A' | 'S'

const options: IDropdownOption[] = [
  { key: 'S', text: 'Investment' },
  { key: 'A', text: 'Account' }
]

const GainLossIncomeDetail: React.FC = () => {
  const theme = useTheme()
  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [sorting, setSorting] = useState<SortingState>([
    { id: GainLossDetailColumnNames.totalGainLoss, desc: true }
  ])
  const [grouping, setGrouping] = useState<GroupingState>([
    GainLossDetailColumnNames.symbolDescription,
    GainLossDetailColumnNames.account
  ])

  const [columnVisibility, setColumnVisibility] = useState({
    [GainLossDetailColumnNames.symbol]: false,
    [GainLossDetailColumnNames.description]: false
  })
  const [viewBy, setViewBy] = useState<ViewBy>('S')
  const onSearchInputChange = (value?: string) => {
    setSearchText(value || '')
  }

  const { apiContextAccounts } = useRdot360SelectedAccountsApiContext()
  const { yearRangeOptions } = useYearRangeOptions(apiContextAccounts)

  const {
    selectedFinancialYear,
    setSelectedFinancialYear,
    isTaxableAccounts,
    setIsTaxableAccounts,
    searchText,
    setSearchText
  } = useGainLossesStore()

  const debouncedSearchText = useDebounce(searchText, 100)

  const handleViewChange = useCallback(
    (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option) {
        setViewBy(option.key as ViewBy)
        setGrouping([...grouping].reverse())
      }
    },
    [grouping]
  )

  const onDateRangeChange = useCallback(
    (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option) {
        setSelectedFinancialYear(option.key as number)
      }
    },
    [setSelectedFinancialYear]
  )

  const skip = !apiContextAccounts?.length
  const { data, isError, isFetching, isUninitialized } =
    useGetRealizedGainLossSecurityQuery(
      skip
        ? skipToken
        : {
            accounts: apiContextAccounts,
            financialYear: selectedFinancialYear,
            taxable: isTaxableAccounts,
            viewBy
          }
    )

  const gainLossData = useMemo(
    () => (skip || isError ? undefined : data),
    [data, isError, skip]
  )

  const gainLossItemDetails = useMemo(() => {
    if (!gainLossData?.ITEMSUMMARY) {
      return []
    }
    return gainLossData.ITEMSUMMARY.flatMap(
      (item: IClosedLotSummaryItem) => item.DETAILSUMMARY || []
    ).flatMap((subItem: IClosedLotDetailSummary) => subItem.DETAIL || [])
  }, [gainLossData?.ITEMSUMMARY])

  const { invalidateTags } = useFinancialsApiUtil()

  const isAccountL1 = useMemo(
    () => grouping[0] === GainLossDetailColumnNames.account,
    [grouping]
  )

  const columns = useMemo(() => getColumnDefinition(isAccountL1), [isAccountL1])

  const table = useReactTable({
    data: gainLossItemDetails,
    columns,
    state: {
      sorting,
      grouping,
      expanded,
      globalFilter: debouncedSearchText,
      columnVisibility
    },
    autoResetExpanded: false,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onExpandedChange: setExpanded,
    onGlobalFilterChange: setSearchText,
    onSortingChange: setSorting,
    onGroupingChange: setGrouping,
    onColumnVisibilityChange: setColumnVisibility,
    globalFilterFn
  })

  const { rows } = table.getRowModel()
  const { rows: preExpandedRows } = table.getPreExpandedRowModel()
  const headers = table.getFlatHeaders()
  const { getToggleAllRowsExpandedHandler } = table
  const isAllRowsExpanded = table.getIsAllRowsExpanded()

  const showNoData = useMemo(
    () => !isUninitialized && !data?.ITEMSUMMARY?.length && !isFetching,
    [data?.ITEMSUMMARY?.length, isFetching, isUninitialized]
  )

  const store = useStore()

  const print = useCallback(
    (masked = true, hideHousehold = true) => {
      const profitValues = preExpandedRows.map((x) =>
        x.getValue(GainLossDetailColumnNames.totalGainLoss)
      )

      openGainLossPrintTab(
        masked,
        hideHousehold,
        store,
        headers,
        rows,
        searchText,
        sum(profitValues)
      )
    },
    [store, headers, rows, searchText, preExpandedRows]
  )

  const exportToExcel = useCallback(async () => {
    if (showNoData) {
      return
    }

    const originalData = preExpandedRows
      .flatMap((row) => row.getLeafRows())
      .filter((leaf) => leaf.depth === 2)
      .map((x) => x.original)

    const columnDef = columns.map((column) => ({
      header: column.header as string
    }))

    const mappedExportData = originalData?.map((dataItem) =>
      fromPairs(
        columns.map((column) => [
          column.header ?? '',
          column.getExportValue?.(dataItem)
        ])
      )
    )
    const worker = new Worker(new URL('./export.ts', import.meta.url))
    const { exportRealizedGainAndLoss } =
      wrap<RealizedGainAndLossExportWorker>(worker)

    const result = await exportRealizedGainAndLoss(mappedExportData, columnDef)
    const filename = `Gain Loss Summary ${format(
      new Date(),
      'MM-dd-yyyy'
    )}.xlsx`
    downloadUrlAsFile(result, filename)
  }, [columns, preExpandedRows, showNoData])

  return (
    <>
      <div
        css={{
          display: 'flex',
          alignItems: 'end',
          columnGap: 20,
          flexWrap: 'wrap',
          rowGap: 10
        }}
      >
        <DetailsNavigator />
        <Dropdown
          label="Year"
          options={yearRangeOptions}
          selectedKey={selectedFinancialYear}
          onChange={onDateRangeChange}
        />
        <Dropdown
          label="View By"
          options={options}
          selectedKey={viewBy}
          onChange={handleViewChange}
        />
        <div
          css={{
            display: 'flex',
            columnGap: 5,
            alignItems: 'center',
            marginBottom: '9px'
          }}
        >
          <div css={{ opacity: isTaxableAccounts ? 0.5 : undefined }}>
            All Accounts
          </div>
          <Toggle
            defaultChecked={isTaxableAccounts}
            onChange={() => {
              setIsTaxableAccounts(!isTaxableAccounts)
            }}
          />
          <div css={{ opacity: isTaxableAccounts ? undefined : 0.5 }}>
            Taxable Accounts
          </div>
        </div>
        <div
          css={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            columnGap: 10
          }}
        >
          <Searchbox searchText={searchText} onChange={onSearchInputChange} />

          <TooltipHost content="Refresh" id="#refreshGainAndLosss">
            <Icon
              type="Refresh"
              width={24}
              height={24}
              onClick={
                isFetching ? undefined : () => invalidateTags(['gainandlosss'])
              }
              color={theme.colors.extraBlue2}
              isDisabled={isFetching}
            />
          </TooltipHost>

          <TooltipHost content="Print" id="#printGainAndLoss">
            <PrintDropdownMenu
              print={print}
              displayDisclaimer={false}
              isDisabled={isFetching || showNoData}
            />
          </TooltipHost>

          <TooltipHost content="Export to Excel" id="#exportToExcel">
            <Icon
              type="Download"
              width={24}
              height={24}
              onClick={isFetching || showNoData ? undefined : exportToExcel}
              color={theme.colors.extraBlue2}
              isDisabled={isFetching || showNoData}
            />
          </TooltipHost>
        </div>
      </div>

      <ActionButton
        label="Expand All Rows"
        onClick={getToggleAllRowsExpandedHandler()}
        styles={{
          root: {
            textDecoration: 'underline'
          }
        }}
        disabled={isFetching || showNoData}
      >
        {!isAllRowsExpanded ? 'Expand' : 'Collapse'} All
      </ActionButton>
      <div css={{ height: 2 }}>
        <ProgressIndicator
          progressHidden={!isFetching}
          styles={{
            itemProgress: { margin: 0, padding: 0 }
          }}
        />
      </div>
      <HorizontalScrollContainer>
        <GainLossHeading headers={headers} />
        <GainLossBody rows={rows} />
        <GainLossFooter headers={headers} />
        <div css={{ fontSize: 10 }}>{disclaimer}</div>
      </HorizontalScrollContainer>
    </>
  )
}

export default GainLossIncomeDetail
