import { Link } from '@fluentui/react'
import { useAiPositions } from 'modules/Advisory/modules/AI/modules/Dashboard/store/useAiPositions'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRdot360Context } from '../../../store/rdot360Context'

const assetClasesToLinkAiDashboard = ['Private Investments', 'Hedge Funds']

const NavigateToAI: React.FC<{
  cellValue?: string
}> = ({ cellValue }) => {
  const navigate = useNavigate()
  const { updateFilters } = useAiPositions()
  const { selectedHouseholdId } = useRdot360Context()

  const updateFiltersAndNavigateToAiDashboard = useCallback(() => {
    if (selectedHouseholdId) {
      updateFilters({ households: [selectedHouseholdId] })
    }
    navigate('/advisory/ai')
  }, [navigate, selectedHouseholdId, updateFilters])

  return (
    <Link onClick={updateFiltersAndNavigateToAiDashboard}>{cellValue}</Link>
  )
}

export const AssetClassCell: React.FC<{ assetClass?: string }> = ({
  assetClass
}) => {
  const isLink = assetClass && assetClasesToLinkAiDashboard.includes(assetClass)
  if (!isLink) {
    return <span>{assetClass}</span>
  }

  return <NavigateToAI cellValue={assetClass} />
}
