import { css } from '@emotion/react'
import { Header, Row } from '@tanstack/react-table'
import { PrintHeaderGlobal } from 'modules/Advisory/modules/Rdot360/shared/PrintHeaderGlobal'
import { IClosedLotSummaryItem } from 'modules/Advisory/modules/Rdot360/store/types'
import { useMemo } from 'react'
import { PrintDisclaimer } from './PrintDisclaimer'
import { PrintHeaderClient } from './PrintHeaderClient'
import { PrintTable } from './PrintTable'

const getClasses = () => ({
  headerGlobal: css({
    borderBottom: '2px solid #676767',
    paddingBottom: '20px'
  }),
  headerClient: css({
    borderBottom: '1px solid #CDCDCD',
    marginBottom: '-70px',
    display: 'flex',
    justifyContent: 'space-between'
  }),
  body: css({
    fontFamily: 'sans-serif',
    '@media screen': {
      maxWidth: '1600px',
      margin: '2rem auto',
      '& .header-global': {
        maxWidth: '1600px'
      },
      '& .small-logo-header': {
        display: 'none'
      }
    },
    '@media print': {
      margin: 0,
      zoom: '60%',
      '@page': {
        size: 'letter landscape'
      }
    }
  })
})

interface IPrintHTMLProps {
  masked: boolean
  hideHousehold: boolean
  headers: Header<IClosedLotSummaryItem, unknown>[]
  rows: Row<IClosedLotSummaryItem>[]
  searchText: string
  netAmount: number
}

export const PrintHTML: React.FC<IPrintHTMLProps> = ({
  masked,
  hideHousehold,
  headers,
  rows,
  searchText,
  netAmount
}) => {
  const classes = useMemo(() => getClasses(), [])
  return (
    <html>
      <head>
        <meta charSet="UTF-8" />
        <title>&nbsp;</title>
        <script>
          {`
              window.onload = function() {
                setTimeout(() => {
                  window.print();
                }, 100)
              }
          `}
        </script>
      </head>
      <body css={classes.body}>
        <header css={classes.headerGlobal}>
          <PrintHeaderGlobal name="Realized Gains/Losses" />
        </header>
        <header css={classes.headerClient}>
          <PrintHeaderClient
            masked={masked}
            hideHousehold={hideHousehold}
            searchText={searchText}
            netAmount={netAmount}
          />
        </header>
        <PrintTable headers={headers} rows={rows} />
        <PrintDisclaimer />
      </body>
    </html>
  )
}
