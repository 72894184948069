import {
  Cell,
  CellContext,
  flexRender,
  Header,
  HeaderContext
} from '@tanstack/react-table'
import { sum } from 'lodash'
import { PercentCellWithColor } from 'modules/Advisory/modules/Rdot360/components/PercentCellWithColor'
import { AccountNumberCell } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/AccountNumberCell'
import { HighlightSearchText } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/HighlightSearchText'
import { USDCellWithColor } from 'modules/Advisory/modules/Rdot360/components/USDCellWithColor'
import { useGainLossesStore } from 'modules/Advisory/modules/Rdot360/features/GainLosses/gainLossesStore'
import { SortIndicator } from 'modules/Advisory/modules/Rdot360/shared/SortIndicator'
import { IClosedLotExtended } from 'modules/Advisory/modules/Rdot360/store/types'
import { FC } from 'react'
import { FormattedDate, FormattedNumber } from 'react-intl'
import { isValidDate } from 'shared'
import { USD } from 'shared/components/Formatting'
import { isNotNullOrUndefined } from 'shared/guards'
import { GainLossDetailColumnNames } from '../../shared'

const getFormattedDate = (date?: string) => {
  return date == null || !isValidDate(date) ? (
    '--'
  ) : (
    <FormattedDate
      day="2-digit"
      month="2-digit"
      year="numeric"
      value={new Date(date)}
    />
  )
}

const notApplicable = '--'

const getIsNullOrNaN = (value: number | null | undefined) =>
  value == null || isNaN(value)

export const SymbolDescriptionCell: FC<
  CellContext<IClosedLotExtended, unknown>
> = ({ getValue, row }) => {
  const { searchText } = useGainLossesStore()
  const symbolName = getValue<string>() || ''
  const symbolDescription = row.original.SECDESCRIPTION || ''
  return (
    <>
      <div>
        <HighlightSearchText text={symbolName} search={searchText} />
      </div>
      <div css={{ fontWeight: 'normal', fontSize: 'smaller' }}>
        <HighlightSearchText text={symbolDescription} search={searchText} />
      </div>
    </>
  )
}

export const GainLossAccountNumberCell: FC<
  CellContext<IClosedLotExtended, unknown>
> = ({ getValue }) => {
  const { searchText } = useGainLossesStore()
  const value = getValue<string>()

  return <AccountNumberCell accountIdOrKey={value} search={searchText} />
}

export const GainLossUSDCell: FC<CellContext<IClosedLotExtended, unknown>> = ({
  getValue
}) => {
  const value = getValue<number>()

  return getIsNullOrNaN(value) ? '--' : <USD value={value} />
}

export const GainLossAggregatedUniqueUSDCell: FC<
  CellContext<IClosedLotExtended, unknown> & {
    isAccountNotApplicable?: boolean
  }
> = ({ getValue, row }, { isAccountNotApplicable }) => {
  const isAccountGrouping = isAccountNotApplicable
    ? row.groupingColumnId === GainLossDetailColumnNames.account
    : false
  if (isAccountGrouping) {
    return notApplicable
  }
  const value = getValue<number>()

  return getIsNullOrNaN(value) ? '--' : <USD value={value} />
}

export const GainLossUSDCellWithColor: FC<
  CellContext<IClosedLotExtended, unknown>
> = ({ getValue }) => {
  const value = getValue<number>()

  return getIsNullOrNaN(value) ? '--' : <USDCellWithColor value={value} />
}

export const GainLossAggregatedUSDCellWithColor: FC<
  CellContext<IClosedLotExtended, unknown> & {
    addPercentageChangeCOSTBASIS?: boolean
  }
> = ({ getValue, row }, { addPercentageChangeCOSTBASIS }) => {
  const value = getValue<number>()
  if (getIsNullOrNaN(value)) {
    return '--'
  }

  if (
    addPercentageChangeCOSTBASIS &&
    row.groupingColumnId === GainLossDetailColumnNames.symbolDescription
  ) {
    const currentRowAggregatedValue = row
      .getLeafRows()
      .filter((leafRow) => !leafRow.getCanExpand())
      .map((leafRow) => leafRow.original.COSTBASIS)
      .filter(isNotNullOrUndefined)
    const totalCurrentRowAggregatedValue = sum(currentRowAggregatedValue)
    const percentageIncrease =
      totalCurrentRowAggregatedValue !== 0
        ? (value / totalCurrentRowAggregatedValue) * 100
        : undefined

    return (
      <>
        <USDCellWithColor value={value} />
        <br />
        <PercentCellWithColor value={percentageIncrease} />
      </>
    )
  }

  return <USDCellWithColor value={value} />
}

export const GainLossDateCell: FC<CellContext<IClosedLotExtended, unknown>> = ({
  getValue
}) => getFormattedDate(getValue<string>())

export const GainLossAggregatedDateCell: FC<
  CellContext<IClosedLotExtended, unknown>
> = ({ getValue, row }) => {
  if (row.groupingColumnId === GainLossDetailColumnNames.account) {
    return notApplicable
  }
  const value = getValue<string[]>()
  if (value.length !== 1) {
    return '--'
  }

  return getFormattedDate(value[0])
}

export const GainLossQuantityCell: FC<
  CellContext<IClosedLotExtended, unknown>
> = ({ getValue, row }) => {
  if (row.groupingColumnId === GainLossDetailColumnNames.account) {
    return notApplicable
  }
  const value = getValue<number>()

  return getIsNullOrNaN(value) ? (
    '--'
  ) : (
    <FormattedNumber
      value={value}
      currencySign="accounting"
      maximumFractionDigits={2}
      minimumFractionDigits={2}
    />
  )
}

export const TotalValueFooter: React.FC<
  HeaderContext<IClosedLotExtended, unknown> & { isUsdWithColor?: boolean }
> = ({ table, column }, { isUsdWithColor }) => {
  const { rows: preExpandedRows } = table.getPreExpandedRowModel()
  const total = sum(
    preExpandedRows.map(({ getValue }) => getValue<number>(column.id) || 0)
  )

  if (total == null) {
    return '--'
  }

  return isUsdWithColor ? (
    <USDCellWithColor value={total} />
  ) : (
    <USD value={total} />
  )
}

export const HeaderCell: FC<{
  header: Header<IClosedLotExtended, unknown>
  cellIndex?: number
}> = ({ header, cellIndex }) => {
  const {
    column: { getToggleSortingHandler }
  } = header
  const sorted = header.column.getIsSorted()
  return (
    <div
      onClick={getToggleSortingHandler()}
      css={{
        display: 'flex',
        alignItems: 'baseline',
        cursor: 'pointer',
        justifyContent: cellIndex && cellIndex !== 1 ? 'flex-end' : 'flex-start'
      }}
    >
      {flexRender(header.column.columnDef.header, header.getContext())}
      <span className={sorted ? undefined : 'column-not-sorted'}>
        <SortIndicator direction={sorted} />
      </span>
    </div>
  )
}

export const FooterCell: FC<{
  header: Header<IClosedLotExtended, unknown>
}> = ({ header }) => (
  <td>{flexRender(header.column.columnDef.footer, header.getContext())}</td>
)

export const TableCell: FC<{
  cell: Cell<IClosedLotExtended, unknown>
}> = ({ cell: dataCell }) => {
  const {
    column: {
      columnDef: { cell, aggregatedCell }
    }
  } = dataCell
  const isAggregated = dataCell.getIsAggregated()
  const cellToRender = isAggregated && aggregatedCell ? aggregatedCell : cell

  return <td>{flexRender(cellToRender, dataCell.getContext())}</td>
}
