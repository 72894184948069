import {
  DirectionalHint,
  HoverCard,
  HoverCardType,
  IHoverCard
} from '@fluentui/react'
import { Link } from 'modules/Advisory/modules/Rdot360/components/shared/Link'
import { ICategoryPosition } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/ICategoryPositionResponse'
import { useRdot360AccountContext } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useCallback, useMemo, useRef } from 'react'
import { useClasses } from 'shared/hooks/useClasses'
import { useInvestmentsTableStore } from '../InvestmentsTable'
import { OrderEntryOptionsContent } from './OrderEntryOptionsContent'

export const getThemedClasses = () => ({
  button: {
    fontSize: '14px',
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline'
    }
  },
  buttonColor: {
    color: '#005CB8 !important'
  }
})

export const OrderEntryMenuCell: React.FC<{
  item?: ICategoryPosition
  isHoldingHeader?: boolean
}> = ({ item, isHoldingHeader }) => {
  const classes = useClasses(getThemedClasses)
  const category = useMemo(() => item?.secinfo?.assetcat, [item])
  const hoverCard = useRef<IHoverCard>(null)
  const { grouping } = useInvestmentsTableStore()
  const { accountLookupByAccountIdOrKey } = useRdot360AccountContext()
  const account = useMemo(() => {
    return item?.acctkey
      ? accountLookupByAccountIdOrKey[item?.acctkey] || {}
      : {}
  }, [item?.acctkey, accountLookupByAccountIdOrKey])
  const { CustodianCode, accounttype, accountStatus } = account

  const isMenuVisible = useMemo(() => {
    const isNFS = CustodianCode === 'NFS'
    const isBrokerage = accounttype === 'Brokerage'
    return (
      accountStatus === 'Open' &&
      isNFS &&
      isBrokerage &&
      grouping[0] === 'Product Type' &&
      (category === 'Equities' ||
        category === 'Common Stock' ||
        category === 'Mutual Funds' ||
        category === 'Options')
    )
  }, [CustodianCode, accountStatus, accounttype, category, grouping])

  const instantDismissCard = useCallback(() => {
    if (hoverCard.current) {
      hoverCard.current.dismiss()
    }
  }, [])
  return (
    isMenuVisible && (
      <div>
        <HoverCard
          plainCardProps={{
            onRenderPlainCard: () => (
              <OrderEntryOptionsContent
                category={category}
                item={item}
                isHoldingHeader={isHoldingHeader}
              />
            ),
            directionalHint: DirectionalHint.leftCenter,
            calloutProps: {
              isBeakVisible: false
            },
            styles: {
              root: {
                borderRadius: '8px'
              }
            }
          }}
          instantOpenOnClick={true}
          shouldBlockHoverCard={() => true}
          type={HoverCardType.plain}
          componentRef={hoverCard}
          sticky={true}
          onCardHide={instantDismissCard}
          style={{ borderRadius: '8px' }}
        >
          <Link
            className={[classes.button, classes.buttonColor].join(' ')}
            css={{ textDecoration: 'none' }}
          >
            Trade
          </Link>
        </HoverCard>
      </div>
    )
  )
}
