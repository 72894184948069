import createCache from '@emotion/cache'
import { ThemeProvider, CacheProvider } from '@emotion/react'
import { Header, Row } from '@tanstack/react-table'
import { IClosedLotSummaryItem } from 'modules/Advisory/modules/Rdot360/store/types'
import { createRoot } from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { Store, AnyAction } from 'redux'
import { whenIdle } from 'shared/async'
import { theme } from 'shared/theme'
import { PrintHTML } from './PrintHTML'

const generateHTML = async (
  masked: boolean,
  hideHousehold: boolean,
  store: Store<unknown, AnyAction>,
  headers: Header<IClosedLotSummaryItem, unknown>[],
  rows: Row<IClosedLotSummaryItem>[],
  searchText: string,
  netAmount: number
) => {
  // Emotion renders the style tags in this div with dynamic classnames for each css prop
  const emotionContainer = document.createElement('div')
  const cache = createCache({
    key: 'print',
    speedy: false,
    container: emotionContainer
  })
  const container = document.createElement('div')
  const root = createRoot(container)
  root.render(
    <Provider store={store}>
      <IntlProvider locale="en">
        <ThemeProvider theme={theme}>
          <CacheProvider value={cache}>
            <PrintHTML
              masked={masked}
              hideHousehold={hideHousehold}
              headers={headers}
              rows={rows}
              searchText={searchText}
              netAmount={netAmount}
            />
          </CacheProvider>
        </ThemeProvider>
      </IntlProvider>
    </Provider>
  )
  await whenIdle()
  container.appendChild(emotionContainer)
  const html = container.innerHTML
  root.unmount()
  return html
}

export const openGainLossPrintTab = async (
  masked: boolean,
  hideHousehold: boolean,
  store: Store<unknown, AnyAction>,
  headers: Header<IClosedLotSummaryItem, unknown>[],
  rows: Row<IClosedLotSummaryItem>[],
  searchText: string,
  netAmount: number
) => {
  const innerHtml = await generateHTML(
    masked,
    hideHousehold,
    store,
    headers,
    rows,
    searchText,
    netAmount
  )
  const blob = new Blob([innerHtml], { type: 'text/html' })
  const url = URL.createObjectURL(blob)
  open(url, '_blank', 'noreferrer')
  setTimeout(() => URL.revokeObjectURL(url), 5000)
}
