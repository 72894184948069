import React from 'react'
import { ValueColorWrapper } from './ValueColorWrapper'

export const PercentCellWithColor: React.FC<{
  value?: number
  showTwoDecimalPrecisionForZero?: true
}> = ({ value, showTwoDecimalPrecisionForZero }) => {
  const zeroPercentToDisplay = showTwoDecimalPrecisionForZero ? '0.00%' : '0'
  const percent =
    value != null
      ? `${value === 0 ? zeroPercentToDisplay : value?.toFixed(2)}%`
      : '--'

  return (
    <ValueColorWrapper value={value}>
      {!!value && value > 0 && '+'}
      {percent}
    </ValueColorWrapper>
  )
}
