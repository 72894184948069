import { Link } from 'modules/Advisory/modules/Rdot360/components/shared/Link'
import { ICategoryPosition } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/ICategoryPositionResponse'
import { useRdot360Context } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useClasses } from 'shared/hooks/useClasses'
import { getEnvironmentConfigApps } from 'store/system'
import { getThemedClasses } from './OrderEntryMenuCell'

interface OrderEntryOptionsProps {
  category?: string
  item?: ICategoryPosition
  isHoldingHeader?: boolean
}

const actionLookup: Record<string, string> = {
  'Buy More': 'Buy',
  Sell: 'Sell',
  'Sell All': 'Sell',
  Exchange: 'Exchange'
}
const productTypeLookup: Record<string, string> = {
  'Common Stock': 'OrderEquity',
  Equties: 'OrderEquity',
  'Mutual Funds': 'OrderFund',
  Options: 'OrderOption'
}

export const OrderEntryOptionsContent: React.FC<OrderEntryOptionsProps> = ({
  category,
  item
}) => {
  const { asOfDate } = useRdot360Context()
  const options = useMemo(
    () =>
      category === 'Mutual Funds'
        ? ['Buy More', 'Sell', 'Exchange']
        : ['Buy More', 'Sell'],
    [category]
  )

  return (
    <div
      css={{
        width: '115px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        borderRadius: '8px',
        border: '1px solid #06262D'
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '20px',
          height: '100%'
        }}
      >
        {options.map((option, index) => (
          <WealthscapeDeepLink
            key={index}
            content={option}
            productType={category ? productTypeLookup[category] : ''}
            action={actionLookup[option]}
            account={item?.acctnum}
            security={item?.secinfo?.secid}
          />
        ))}
        {!asOfDate && (
          <WealthscapeDeepLink
            content="Sell All"
            productType={category ? productTypeLookup[category] : ''}
            action="Sell"
            account={item?.acctnum}
            security={item?.secinfo?.secid}
            quantity={item?.shrqnts?.unit}
          />
        )}
      </div>
    </div>
  )
}

const Button: React.FC<{
  content: string
  clickAction?: React.MouseEventHandler<HTMLAnchorElement>
}> = ({ content, clickAction }) => {
  const classes = useClasses(getThemedClasses)
  return (
    <Link
      className={classes.button}
      css={{ textDecoration: 'none', paddingBottom: '20px' }}
      onClick={(event) => clickAction && clickAction(event)}
    >
      {content}
    </Link>
  )
}

const WealthscapeDeepLink: React.FC<{
  content: string
  productType: string
  action?: string
  account?: string
  security?: string
  quantity?: number
}> = ({ content, productType, action, account, security, quantity }) => {
  const apps = useSelector(getEnvironmentConfigApps)
  const wealthscapeAppId = apps?.['wealthscape']?.appId
  const rootUrl = `https://myapps.microsoft.com/signin/${wealthscapeAppId}?RelayState=`
  const relayState = encodeURIComponent(
    `${
      apps?.['wealthscape']?.rootUrl
    }/bd/generic_bportal/alc/${productType}?Account=${account}&Action=${action}${
      quantity ? `&Quantity=${quantity}` : ''
    }&Security=${security}`
  )
  const wealthscapeApplicationUrl = `${rootUrl}${relayState}`
  return (
    <Button
      content={content}
      clickAction={() => window.open(wealthscapeApplicationUrl)}
    />
  )
}
