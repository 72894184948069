import {
  AccessorFnColumnDef,
  CellContext,
  HeaderContext,
  Row
} from '@tanstack/react-table'
import { format } from 'date-fns'
import { sum, sumBy } from 'lodash'
import { AccruedIncome } from 'modules/Advisory/modules/Rdot360/components/AccruedIncome/AccruedIncome'
import { PercentCellWithColor } from 'modules/Advisory/modules/Rdot360/components/PercentCellWithColor'
import { AccountNumberCell } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/AccountNumberCell'
import { HighlightSearchText } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/HighlightSearchText'
import { USDCellWithColor } from 'modules/Advisory/modules/Rdot360/components/USDCellWithColor'
import {
  Icon,
  IconType
} from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { ICategoryPosition } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/ICategoryPositionResponse'
import { useRdot360Context } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { IRdot360Account } from 'modules/Advisory/modules/Rdot360/store/rdot360Context/useRdot360AccountContext'
import React, { useMemo } from 'react'
import { FormattedNumber } from 'react-intl'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { USD } from 'shared/components/Formatting'
import { rdot360TableStyles } from '../../../../shared/tableStyles'
import { useInvestmentsDetailsUIState } from '../../InvestmentsDetailsUIState'
import { AssetClassCell } from '../../shared/AssetClassCell'
import { ChangeCellWithPercent } from '../../shared/ChangeCellWithPercent'
import { OrderEntryMenuCell } from '../DeepLink/OrderEntryMenuCell'
import { investmentsTableColumnNames } from './shared'
import { useInvestmentsTableStore } from './store'
import { TodaysChangeAggregatedCell } from './TodaysChangeAggregatedCell'

export const createDescriptionCell = ({
  forceMaskAccountNumber
}: {
  forceMaskAccountNumber?: boolean
} = {}) => {
  const Component: React.FC<CellContext<ICategoryPosition, unknown>> = (
    props
  ) => {
    const { searchText } = useInvestmentsDetailsUIState()
    const { row, cell } = props
    const { grouping } = useInvestmentsTableStore()
    const isGrouped = row.getIsGrouped()
    const isExpanded = row.getIsExpanded()
    const canExpand = row.getCanExpand()
    const isSelected = row.getIsSelected()
    const isSecurityRow =
      row.groupingColumnId === investmentsTableColumnNames.secid ||
      (!grouping?.includes(investmentsTableColumnNames.secid) &&
        !row.groupingColumnId)
    const depth = row.depth
    const showAccountGroup =
      !isGrouped && grouping.includes(investmentsTableColumnNames.secid)
    const columnId =
      grouping?.[depth] ||
      (showAccountGroup
        ? investmentsTableColumnNames.accountNumber
        : cell.column.id)

    const value = row.getValue<string>(columnId)

    const isSymbol =
      columnId === investmentsTableColumnNames.description ||
      columnId === investmentsTableColumnNames.secid

    const isAccount = columnId === investmentsTableColumnNames.accountNumber

    const isAssetClass = columnId === investmentsTableColumnNames.assetClass
    const assetLevels = row.original.assetLevels
      ?.map(
        (l) => `${l.assetcat === 'Cash' ? 'Cash & Equivalents' : l.assetcat} /`
      )
      .join(' ')

    const [expandIcon, collapseIcon]: [IconType, IconType] = isSecurityRow
      ? ['ChevronDown', 'ChevronUp']
      : ['Add', 'Subtract']

    const showExpand = (canExpand && !isExpanded) || (!canExpand && !isSelected)

    return (
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div
          style={{ cursor: 'pointer', width: '30px', minWidth: '30px' }}
          onClick={() =>
            row.groupingColumnId ? row.toggleExpanded() : row.toggleSelected()
          }
        >
          <Icon
            type={showExpand ? expandIcon : collapseIcon}
            height={12}
            width={12}
            color="black"
          />
        </div>
        <div css={{ flexGrow: 1, display: 'flex', minWidth: 0 }}>
          {isSymbol && <SymbolCell {...props} />}
          {isAccount && (
            <AccountCell
              accountIdOrKey={row.original.acctkey || value}
              search={searchText}
              forceMaskAccountNumber={forceMaskAccountNumber}
            />
          )}
          {!isSymbol && !isAccount && (
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2px',
                whiteSpace: 'nowrap'
              }}
            >
              {isAssetClass && assetLevels && (
                <div css={{ color: '#676767', fontSize: '11px' }}>
                  {assetLevels}
                </div>
              )}
              <div css={{ display: 'flex', fontWeight: 'bold' }}>
                <AssetClassCell assetClass={value} />
                {isGrouped && (
                  <div style={{ marginLeft: '5px' }}>
                    ({row?.subRows?.length ?? 0})
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
  return Component
}
const DescriptionCellWrapper = createDescriptionCell()

const AccountCell: React.FC<{
  accountIdOrKey: string
  search?: string
  forceMaskAccountNumber?: boolean
}> = ({ accountIdOrKey, search, forceMaskAccountNumber }) => {
  return (
    <div
      style={{
        overflow: 'hidden'
      }}
    >
      <AccountNumberCell
        accountIdOrKey={accountIdOrKey}
        displayFirmName={false}
        search={search}
        forceMaskAccountNumber={forceMaskAccountNumber}
      />
    </div>
  )
}

const AccruedIncomeCell: React.FC<CellContext<ICategoryPosition, unknown>> = ({
  row,
  getValue
}) => {
  const accruIncome = getValue<number>()
  let accruDividends = row.original.accruDividends
  let accruInterest = row.original.accruInterest

  const leafRows = row.getLeafRows().filter((leaf) => !leaf.getIsGrouped())
  if (leafRows?.length) {
    const originalItems = leafRows.map((item) => item.original)
    accruDividends = sum(originalItems.map((item) => item.accruDividends))
    accruInterest = sum(originalItems.map((item) => item.accruInterest))
  }

  return (
    <AccruedIncome
      dividends={accruDividends}
      income={accruIncome}
      interest={accruInterest}
    />
  )
}

const SymbolCell: React.FC<CellContext<ICategoryPosition, unknown>> = ({
  row
}) => {
  const { searchText } = useInvestmentsDetailsUIState()
  const description = row.original.secinfo?.secname || ''

  return (
    <div css={{ display: 'flex', flexDirection: 'column', minWidth: 0 }}>
      <div>
        <HighlightSearchText
          text={row.original.secinfo?.secid || ''}
          search={searchText}
        />
      </div>
      <div
        title={description}
        css={[rdot360TableStyles.nowrap, { flexGrow: 1 }]}
      >
        <HighlightSearchText text={description} search={searchText} />
      </div>
    </div>
  )
}

const canAggregate = (columnId: string, row: Row<ICategoryPosition>) =>
  row.groupingColumnId === investmentsTableColumnNames.secid ||
  !row.groupingColumnId ||
  [
    investmentsTableColumnNames.value,
    investmentsTableColumnNames.dayChange,
    investmentsTableColumnNames.unrealized,
    investmentsTableColumnNames.costBasis,
    investmentsTableColumnNames.accruedIncome
  ].includes(columnId)

const AggregatedAccruedIncomeCell: React.FC<
  CellContext<ICategoryPosition, unknown>
> = (props) => {
  const { column, row } = props
  if (!canAggregate(column.id, row)) {
    return null
  }

  return <AccruedIncomeCell {...props} />
}

const AggregatedNumberCell: React.FC<
  CellContext<ICategoryPosition, unknown>
> = (props) => {
  const { column, row } = props
  if (!canAggregate(column.id, row)) {
    return null
  }

  return <FormattedNumberCell {...props} />
}

const AggregatedPercentCell: React.FC<
  CellContext<ICategoryPosition, unknown>
> = (props) => {
  const { column, row } = props
  if (!canAggregate(column.id, row)) {
    return null
  }

  return <FormattedPercentCell {...props} />
}

const AggregatedUSDCell: React.FC<CellContext<ICategoryPosition, unknown>> = (
  props
) => {
  const { row, column } = props
  if (!canAggregate(column.id, row)) {
    return null
  }

  return <USDCell {...props} />
}

const FormattedNumberCell: React.FC<
  CellContext<ICategoryPosition, unknown>
> = ({ getValue }) => {
  const value = getValue<number>()
  const hasDecimals = value % 1 !== 0
  return value != null ? (
    <FormattedNumber
      maximumFractionDigits={hasDecimals ? 4 : 0}
      minimumFractionDigits={hasDecimals ? 2 : 0}
      value={value}
    />
  ) : (
    <>--</>
  )
}

const FormattedPercentCell: React.FC<
  CellContext<ICategoryPosition, unknown>
> = ({ getValue }) => {
  const value = getValue<number>()
  const fractionDigits = value ? 2 : 0
  return value != null ? (
    <>
      <FormattedNumber
        maximumFractionDigits={fractionDigits}
        minimumFractionDigits={fractionDigits}
        value={value}
      />
      %
    </>
  ) : (
    <>--</>
  )
}

const AggregatedPriceChangeCell: React.FC<
  CellContext<ICategoryPosition, unknown>
> = (props) => {
  const { row } = props
  const isValid =
    row.groupingColumnId === investmentsTableColumnNames.secid ||
    !row.groupingColumnId

  if (!isValid) {
    return null
  }

  return <ChangeCellWithPercent {...props} />
}

const USDCell: React.FC<CellContext<ICategoryPosition, unknown>> = ({
  getValue
}) => {
  const value = getValue<number>()
  return value && value !== Infinity ? (
    <USD value={value} currencySign="standard" />
  ) : (
    <>--</>
  )
}

// const DateCell: React.FC<CellContext<ICategoryPosition, unknown>> = ({
//   getValue
// }) => {
//   const value = getValue<string>()
//   const date = new Date(value)
//   return isValid(date) ? (
//     <div>
//       <FormattedDate
//         day="2-digit"
//         month="2-digit"
//         year="numeric"
//         value={date}
//       />

//       <div>
//         <FormattedTime value={date} />
//       </div>
//     </div>
//   ) : (
//     <>--</>
//   )
// }

const UnrealizedCell: React.FC<CellContext<ICategoryPosition, unknown>> = ({
  row,
  getValue
}) => {
  const unrealized = getValue<number>() || 0
  const costbasis = row.getValue<number>(investmentsTableColumnNames.costBasis)

  const percent = costbasis && (unrealized / costbasis) * 100
  const fractionDigits = unrealized ? 2 : 0

  return (
    <div css={{ display: 'inline-flex', flexDirection: 'column' }}>
      <div>
        <USDCellWithColor value={unrealized} fractionDigit={fractionDigits} />
      </div>
      {!!percent && (
        <div>
          <PercentCellWithColor value={percent} />
        </div>
      )}
    </div>
  )
}

const PriceCell: React.FC<CellContext<ICategoryPosition, unknown>> = ({
  row,
  getValue
}) => {
  const { asOfDate } = useRdot360Context()
  const price = getValue<number>()
  const priceAsofDate = useMemo(() => {
    const compareDate = asOfDate || format(new Date(), 'yyyy-MM-dd')

    const priceDate =
      row?.original?.pricing?.priceAsofDate &&
      format(
        parseDateISOStringInLocalTimezone(
          row?.original?.pricing?.priceAsofDate
        ),
        'yyyy-MM-dd'
      )

    return !!(priceDate && compareDate) &&
      new Date(priceDate) < new Date(compareDate)
      ? priceDate
      : undefined
  }, [asOfDate, row?.original?.pricing?.priceAsofDate])

  return (
    <div css={{ display: 'inline-flex', flexDirection: 'column' }}>
      <div>
        {price != null ? (
          <FormattedNumber
            value={price}
            style="currency"
            currency="USD"
            currencySign={'standard'}
            maximumFractionDigits={4}
            minimumFractionDigits={2}
          />
        ) : (
          <>--</>
        )}
      </div>
      {!!priceAsofDate && <div css={{ fontSize: '10px' }}>{priceAsofDate}</div>}
    </div>
  )
}

const SumFooter: React.FC<HeaderContext<ICategoryPosition, unknown>> = ({
  table,
  column
}) => {
  const total = sum(
    table
      .getPreExpandedRowModel()
      .rows.map(({ getValue }) => getValue<number>(column.id) || 0)
  )

  return <USD value={total} fractionDigits={2} currencySign={'standard'} />
}

const SumFooterColor: React.FC<HeaderContext<ICategoryPosition, unknown>> = ({
  table,
  column
}) => {
  const total = sum(
    table
      .getPreExpandedRowModel()
      .rows.map(({ getValue }) => getValue<number>(column.id) || 0)
  )
  return <USDCellWithColor value={total} fractionDigit={2} />
}

export const getInvestmentsTableColumnDefs = (
  accountLookup: Record<string, IRdot360Account | undefined>
): AccessorFnColumnDef<ICategoryPosition>[] => [
  {
    id: investmentsTableColumnNames.accountNumber,
    header: investmentsTableColumnNames.accountNumber,
    enableGlobalFilter: true,
    aggregationFn: 'unique',
    accessorFn: (item) => item.acctnum
  },
  {
    id: investmentsTableColumnNames.nickname,
    header: investmentsTableColumnNames.nickname,
    enableGlobalFilter: true,
    aggregationFn: 'unique',
    accessorFn: (item) => accountLookup?.[item.acctnum || '']?.preferredNickname
  },
  {
    id: investmentsTableColumnNames.assetClass,
    header: investmentsTableColumnNames.assetClass,
    enableGlobalFilter: false,
    aggregationFn: 'unique',
    accessorFn: (item) =>
      item.secinfo?.assetcat === 'Cash'
        ? 'Cash & Equivalents'
        : item.secinfo?.assetcat
  },
  {
    id: investmentsTableColumnNames.productType,
    header: investmentsTableColumnNames.productType,
    enableGlobalFilter: false,
    aggregationFn: 'unique',
    accessorFn: (item) => item.secinfo?.assetcat
  },
  {
    id: investmentsTableColumnNames.sectorClass,
    header: investmentsTableColumnNames.sectorClass,
    enableGlobalFilter: false,
    aggregationFn: 'unique',
    accessorFn: (item) => item.secinfo?.assetcat
  },
  {
    id: investmentsTableColumnNames.secid,
    header: investmentsTableColumnNames.secid,
    enableGlobalFilter: true,
    aggregationFn: 'unique',
    accessorFn: (item) => item.secinfo?.secid
  },
  {
    id: investmentsTableColumnNames.symbol,
    header: investmentsTableColumnNames.symbol,
    enableHiding: true,
    accessorFn: (item) => item.secinfo?.secid
  },
  {
    id: investmentsTableColumnNames.desc,
    header: investmentsTableColumnNames.desc,
    enableHiding: true,
    accessorFn: (item) => item.secinfo?.secname
  },
  {
    id: investmentsTableColumnNames.description,
    header: investmentsTableColumnNames.description,
    enableGlobalFilter: true,
    aggregationFn: 'unique',
    accessorFn: (item) => item.secinfo?.secname || item.secinfo?.secid,
    cell: DescriptionCellWrapper,
    aggregatedCell: DescriptionCellWrapper,
    size: 350,
    minSize: 50,
    footer: 'Total'
  },
  {
    id: investmentsTableColumnNames.quantity,
    header: investmentsTableColumnNames.quantity,
    enableGlobalFilter: false,
    aggregationFn: 'sum',
    accessorFn: (item) => item.shrqnts?.unit,
    cell: FormattedNumberCell,
    aggregatedCell: AggregatedNumberCell,
    size: 100,
    minSize: 50
  },
  {
    id: investmentsTableColumnNames.price,
    header: investmentsTableColumnNames.price,
    enableGlobalFilter: false,
    aggregationFn: 'min',
    accessorFn: (item) => item.pricing?.rcntprice,
    cell: PriceCell,
    aggregatedCell: AggregatedUSDCell,
    size: 100,
    minSize: 50
  },
  {
    id: investmentsTableColumnNames.priceChange,
    header: investmentsTableColumnNames.priceChange,
    enableGlobalFilter: false,
    accessorFn: (item) =>
      item.pricing?.pricechg ||
      (item.pricing?.rcntprice || 0) - (item.pricing?.clsprice || 0),
    cell: ChangeCellWithPercent,
    aggregationFn: 'min',
    aggregatedCell: AggregatedPriceChangeCell,
    size: 70,
    minSize: 50
  },
  {
    id: investmentsTableColumnNames.value,
    header: investmentsTableColumnNames.value,
    enableGlobalFilter: false,
    accessorFn: (item) => item.mktvals?.rcntmktval,
    cell: USDCell,
    aggregatedCell: AggregatedUSDCell,
    size: 170,
    minSize: 50,
    footer: SumFooter
  },
  {
    id: investmentsTableColumnNames.dayChange,
    header: investmentsTableColumnNames.dayChange,
    enableGlobalFilter: false,
    accessorFn: (item) => item.mktvals?.todaysGainLoss,
    cell: TodaysChangeAggregatedCell,
    aggregationFn: 'sum',
    aggregatedCell: TodaysChangeAggregatedCell,
    size: 150,
    minSize: 50,
    footer: SumFooterColor
  },
  {
    id: investmentsTableColumnNames.unrealized,
    header: investmentsTableColumnNames.unrealized,
    size: 150,
    minSize: 50,
    enableGlobalFilter: false,
    accessorFn: (item) => item.unrealizedValue,
    cell: UnrealizedCell,
    aggregationFn: 'sum',
    aggregatedCell: UnrealizedCell,
    footer: SumFooterColor
  },
  {
    id: investmentsTableColumnNames.unitCost,
    header: investmentsTableColumnNames.unitCost,
    enableGlobalFilter: false,
    accessorFn: (item) => item.costbasis?.avgcostpershare,
    aggregationFn: (_columnId, leafRows) => {
      const costBasis = sumBy(
        leafRows,
        ({ getValue }) =>
          getValue<number | undefined>(investmentsTableColumnNames.costBasis) ||
          0
      )

      const quantity = sumBy(
        leafRows,
        ({ getValue }) =>
          getValue<number | undefined>(investmentsTableColumnNames.quantity) ||
          0
      )

      return costBasis && costBasis / quantity
    },
    cell: USDCell,
    aggregatedCell: AggregatedUSDCell,
    size: 100,
    minSize: 50
  },
  {
    id: investmentsTableColumnNames.costBasis,
    header: investmentsTableColumnNames.costBasis,
    aggregationFn: 'sum',
    enableGlobalFilter: false,
    accessorFn: (item) => item.costbasis?.costbasis,
    cell: USDCell,
    aggregatedCell: AggregatedUSDCell,
    size: 150,
    minSize: 50,
    footer: SumFooter
  },
  {
    id: investmentsTableColumnNames.yield,
    header: investmentsTableColumnNames.yield,
    aggregationFn: 'min',
    enableGlobalFilter: false,
    accessorFn: (item) => item.secinfo?.yieldPct,
    cell: FormattedPercentCell,
    aggregatedCell: AggregatedPercentCell,
    size: 60,
    minSize: 50
  },
  {
    id: investmentsTableColumnNames.accruedIncome,
    header: investmentsTableColumnNames.accruedIncome,
    aggregationFn: 'sum',
    enableGlobalFilter: false,
    accessorFn: (item) => item?.accruIncome,
    cell: AccruedIncomeCell,
    aggregatedCell: AggregatedAccruedIncomeCell,
    size: 100,
    minSize: 50,
    footer: SumFooter
  },
  {
    id: 'orderEntryMenu',
    header: '',
    enableSorting: false,
    accessorFn: () => null,
    cell: () => null,
    aggregatedCell: (item) =>
      item.row.subRows.length === 0 ? (
        <OrderEntryMenuCell item={item.row.original} isHoldingHeader={false} />
      ) : null,
    size: 50
  }
  // {
  //   id: investmentsTableColumnNames.asOf,
  //   header: () => {
  //     return (
  //       <TooltipHost content="Coming Soon">
  //         {investmentsTableColumnNames.asOf}
  //       </TooltipHost>
  //     )
  //   },
  //   enableGlobalFilter: false,
  //   accessorFn: () => undefined,
  //   cell: () => null,
  //   aggregatedCell: () => null,
  //   size: 100,
  //   minSize: 50
  // }
]
